<template>
  <div class="container container-fluid">
    <h2>Detail Fuel Chart</h2>
    <canvas id="fuel-chart-detail" style="width: 100vw; height: 30vh"></canvas>
    <button class="btn btn-success" @click="detailChart.resetZoom()">Reset</button>
    <button class="btn btn-success" @click="detailChart.zoom(1.1)">Zoom in</button>
    <button class="btn btn-success" @click="detailChart.zoom(0.9)">Zoom out</button>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import zoomPlugin from "chartjs-plugin-zoom";
import axios from "axios"
// import cloneDeep from "lodash/cloneDeep";
// import _ from 'lodash'
Chart.register(zoomPlugin);
// const clone = require('lodash.clone');
// const clonedeep = require('lodash.clonedeep');

import { ChartData as fuelChartDetail } from "@/data/fuel-data-detail";
// const d =  Object.assign({}, fuelChartData); // Shallow Copy
// const detailFuel = JSON.parse(JSON.stringify(fuelChartData)); //Doesnot copy functions like for tooltip and Date
// const fuelChartDetail = cloneDeep(ChartData);
// import { fuelChartData as fuelChartDetail } from "@/data/fuel-data";
export default {
  name: "FuelChart",
  props: { msg: { type: String } },
  data() {
    return {
      fuelChartDetail: fuelChartDetail,
      detailChart: null,
      sourceData: null,
    };
  },

  methods: {
    resetZoomChart() {
      this.chart.resetZoom();
    },

    renderChart() {
      //Detail Section

      const ctxDetail = document.getElementById("fuel-chart-detail");
      let parsed = JSON.parse(this.sourceData.detail_fuel_report);
      let fuelDetail = parsed.fuel;
      // console.table(fuelDetail)
      let detailTimestamp = [];
      let detailLevels = [];
      let detailLitres = [];
      let detailIgnitions = [];
      fuelDetail.forEach((element) => {
        detailTimestamp.push(element["timestamp"]);
        detailLevels.push(element["level"]);
        detailLitres.push(element["litre"]);
        detailIgnitions.push(element["ignition"]);
      });
      // console.log(detailTimestamp);
      this.fuelChartDetail.type = "line";
      this.fuelChartDetail.data.labels = detailTimestamp;
      this.fuelChartDetail.data.levels = detailLevels;
      this.fuelChartDetail.data.litres = detailLitres;
      this.fuelChartDetail.data.ignitions = detailIgnitions;
      console.log("ignitions");
      this.fuelChartDetail.data.datasets[0].label = "Fuel/Time by Level";
      this.fuelChartDetail.data.datasets[0].data = detailLevels;
      this.fuelChartDetail.data.datasets[1].label = "Fuel/Time by Litre";
      this.fuelChartDetail.data.datasets[1].data = detailLitres;
      this.detailChart = new Chart(ctxDetail, this.fuelChartDetail);
    },

    getWebData() {
      const url = "https://gyc5tlj7k26jrtgfpy4tzte2240hatpe.lambda-url.us-east-1.on.aws/";
      return axios
        .get(url)
        .then((response) => {
          // let result = JSON.parse(response.data)
          let result = response.data;
          // console.log(response.data.distance_km);
          this.sourceData = result;
          // console.log(this.sourceData);
          console.log("kms", this.sourceData.distance_km);
          return "Success";
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    this.getWebData().then((response) => {
      console.log(response);
      this.renderChart(0);
    });
  },
};
</script>
