<template>
  <div class="container container-flued">
      <h2>Speed Time Chart</h2>
    <canvas id="speed-chart-self" style="width: 100vw; height: 30vh"></canvas>
    <vue-chartjs></vue-chartjs>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import zoomPlugin from "chartjs-plugin-zoom";
import axios from "axios"
// import cloneDeep from "lodash/cloneDeep"
// import _ from 'lodash'
Chart.register(zoomPlugin);
// const clone = require('lodash.clone');
// const clonedeep = require('lodash.clonedeep');

import {ChartData as speedChartData} from "@/data/speed-data";
// const d =  Object.assign({}, fuelChartData); // Shallow Copy
// const detailFuel = JSON.parse(JSON.stringify(fuelChartData)); //Doesnot copy functions like for tooltip and Date
// const speedData = cloneDeep(fuelChartData)
// import { fuelChartData as detailInfo } from "@/data/fuel-data";
export default {
  name: "SpeedChart",
  data() {
    return {
      speedChartData: speedChartData,
      litre: [],
      level: [],
    };
  },
  methods: {
  renderChart() {
    const ctxSpeed = document.getElementById("speed-chart-self");
    let parsed = JSON.parse(this.sourceData.speed_report);
    let speed = parsed.speed;
    console.warn(speed)
    let speedTimestamp = [];
    let speeds = [];
    speed.forEach((element) => {
      speedTimestamp.push(element["timestamp"]);
      speeds.push(element["speed"]);
    });
    console.log(speedTimestamp);
    this.speedChartData.speeds = speeds;
    console.log(speeds)
    this.speedChartData.data.labels = speedTimestamp;
    this.speedChartData.data.datasets[0].label = "Speed/Time";
    this.speedChartData.data.datasets[0].data = speeds;
   
    
    new Chart(ctxSpeed, this.speedChartData);
  },
getWebData() {
      const url = "https://gyc5tlj7k26jrtgfpy4tzte2240hatpe.lambda-url.us-east-1.on.aws/";
      return axios
        .get(url)
        .then((response) => {
          // let result = JSON.parse(response.data)
          let result = response.data;
          // console.log(response.data.distance_km);
          this.sourceData = result;
          // console.log(this.sourceData);
          console.log("kms", this.sourceData.distance_km);
          return "Success";
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    this.getWebData().then((response) => {
      console.log(response);
      this.renderChart(0);
    });
  },
};
</script>
