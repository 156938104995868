<template>
  <div class="container container-flued">
    <h2>Distance Time Chart</h2>

    <canvas id="distance-chart-self" style="width: 100vw; height: 30vh"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import zoomPlugin from "chartjs-plugin-zoom";
import axios from "axios";
// import cloneDeep from "lodash/cloneDeep"
// import _ from 'lodash'
Chart.register(zoomPlugin);
// const clone = require('lodash.clone');
// const clonedeep = require('lodash.clonedeep');

import { ChartData as distanceChartData } from "@/data/distance-data";
// const d =  Object.assign({}, fuelChartData); // Shallow Copy
// const detailFuel = JSON.parse(JSON.stringify(fuelChartData)); //Doesnot copy functions like for tooltip and Date
// const distanceData = cloneDeep(fuelChartData)
// import { fuelChartData as detailInfo } from "@/data/fuel-data";
export default {
  name: "DistanceChart",
  data() {
    return {
      distanceChartData: distanceChartData,
      sourceData: null,
    };
  },
  methods: {
    renderChart() {
      const ctxDistance = document.getElementById("distance-chart-self");
      let parsed = JSON.parse(this.sourceData.distance_report);
      let distance = parsed.distance;
      console.warn(distance);
      let distanceTimestamp = [];
      let distances = [];
      let uphill = [];
      let downhill = [];
      let terrain = [];
      let slope = [];
      distance.forEach((element) => {
        distanceTimestamp.push(element["timestamp"]);
        distances.push(element["distance"]);
        uphill.push(element["uphill"]);
        downhill.push(element["downhill"]);
        terrain.push(element["terrain"]);
        slope.push(element["slope"]);
      });
      console.log(distanceTimestamp);
      this.distanceChartData.distances = distances;
      console.log(distances);
      this.distanceChartData.data.labels = distanceTimestamp;
      this.distanceChartData.data.datasets[0].label = "Distance/Time";
      this.distanceChartData.data.datasets[0].data = distances;
      this.distanceChartData.data.datasets[0].uphill = uphill;
      this.distanceChartData.data.datasets[0].downhill = downhill;
      this.distanceChartData.data.datasets[0].terrain = terrain;
      this.distanceChartData.data.datasets[0].slope = slope;

      new Chart(ctxDistance, this.distanceChartData);
    },

    getWebData() {
      const url = "https://gyc5tlj7k26jrtgfpy4tzte2240hatpe.lambda-url.us-east-1.on.aws/";
      return axios
        .get(url)
        .then((response) => {
          // let result = JSON.parse(response.data)
          let result = response.data;
          // console.log(response.data.distance_km);
          this.sourceData = result;
          // console.log(this.sourceData);
          console.log("kms", this.sourceData.distance_km);
          return "Success";
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    this.getWebData().then((response) => {
      console.log(response);
      this.renderChart(0);
    });
  },
};
</script>
