<template>
  <h1>fleet.phnot.com charts</h1>

  <!-- <div class="">
    <button title="Previous Day" @click="update_charts('previous')">
      &LeftAngleBracket;&LeftAngleBracket; Previous
    </button>
    <input type="date" v-model="local_date" @change="update_charts('current')" />

    <button title="Next day" @click="update_charts('next')">
      Next &RightAngleBracket;&RightAngleBracket;
    </button>
  </div>
  {{refresher}}{{currentDate}} -->
  <!-- <FuelChart :reports="sourceData" :ref="refresher"></FuelChart> -->
  <FuelChart ></FuelChart>
  <FuelChartDetail />
  <SpeedChart />
  <DistanceChart />
</template>

<script>
import FuelChart from "./components/FuelChart.vue";
import FuelChartDetail from "./components/FuelChartDetail.vue";
import SpeedChart from "./components/SpeedChart.vue";
import DistanceChart from "./components/DistanceChart.vue";
import { DateTime } from "luxon";
import axios from "axios";

export default {
  name: "App",
  components: {
    FuelChart,
    FuelChartDetail,
    SpeedChart,
    DistanceChart,
  },
  data() {
    return {
      sourceData: {},
      local_date: DateTime.now().toFormat("yyyy-MM-dd"),
      currentDate: DateTime.now(),
      refresher: 0,
    };
  },

  methods: {
    update_charts(param = "current") {
      console.log(param);
      let days_date = null

      if (param === "next") {
        days_date = this.currentDate.plus({ days: 1 });
      } else if (param === "previous") {
        days_date = this.currentDate.plus({ days: -1 });
      } else {
        days_date = DateTime.fromSQL(this.local_date);
        // if (days_date === this.currentDate) return
      }

      console.log(days_date);
      this.currentDate = days_date;
      this.local_date = days_date.toString().slice(0, 10);
      console.log(this.local_date);
      this.getWebData().then((response) => {
        console.log(response);
      });
      this.refresher++;
    },

    getWebData() {
      // const url = "https://gyc5tlj7k26jrtgfpy4tzte2240hatpe.lambda-url.us-east-1.on.aws/date=2022-06-10";
      const dt = String(this.currentDate.toString().slice(0, 10));
      const url = "https://5uljd3w6h1.execute-api.us-east-1.amazonaws.com/reports" + "?date=" + dt;
    //   const url = "https://5uljd3w6h1.execute-api.us-east-1.amazonaws.com/reports?date=2022-06-10";
      return axios
        .get(url)
        .then((response) => {
          // let result = JSON.parse(response.data)
          let result = response.data;
          // console.log(response.data.distance_km);
          this.sourceData = result;
          console.log(this.sourceData);
          console.log("kms", this.sourceData.distance_km);
          return "Success";
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    // this.getWebData().then((response) => {
    //   console.log(response);
    //   this.refresher++
    // });
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
