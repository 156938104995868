<template>
  <div class="container container-fluid" style="height=400px">
    <h2>Fuel Time Chart</h2>
    <div class="">
      <button title="Previous Day" @click="update_charts('previous')">
        &LeftAngleBracket;&LeftAngleBracket; Previous
      </button>
      <input type="date" v-model="local_date" @change="update_charts('current')" />

      <button title="Next day" @click="update_charts('next')">
        Next &RightAngleBracket;&RightAngleBracket;
      </button>
      <p>Volume {{ volume }} litres</p>
    </div>
    <canvas id="fuel-chart"></canvas>

    <button class="btn btn-success" @click="inPercent">In Percent</button>
    <button class="btn btn-success" @click="inLitres">In Litres</button>
    <button class="btn btn-success" @click="resetZoomChart">Reset</button>
    <button class="btn btn-success" @click="fuelChart.zoom(1.1)">Zoom in</button>
    <button class="btn btn-success" @click="fuelChart.zoom(0.9)">Zoom out</button>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import zoomPlugin from "chartjs-plugin-zoom";
import axios from "axios";
import { DateTime } from "luxon";
// import cloneDeep from "lodash/cloneDeep";
// import _ from 'lodash'
Chart.register(zoomPlugin);
// const clone = require('lodash.clone');
// const clonedeep = require('lodash.clonedeep');

import { ChartData } from "@/data/fuel-data";
// const d =  Object.assign({}, fuelChartData); // Shallow Copy
// const detailFuel = JSON.parse(JSON.stringify(fuelChartData)); //Doesnot copy functions like for tooltip and Date
// const detailInfo = cloneDeep(ChartData);
// import { fuelChartData as detailInfo } from "@/data/fuel-data";
export default {
  name: "FuelChart",
  // props: {
  //     reports: {
  //         type: Object
  //     }
  // },
  data() {
    return {
      sourceData: null,
      fuelChartData: ChartData,
      fuelChart: null,
      local_date: DateTime.now().toFormat("yyyy-MM-dd"),
      currentDate: DateTime.now(),
      refresher: 0,
    };
  },

  methods: {
    update_charts(param = "current") {
      console.log(param);
      let days_date = null;

      if (param === "next") {
        days_date = this.currentDate.plus({
          days: 1,
        });
      } else if (param === "previous") {
        days_date = this.currentDate.plus({
          days: -1,
        });
      } else {
        days_date = DateTime.fromSQL(this.local_date);
        // if (days_date === this.currentDate) return
      }

      console.log(days_date);
      this.currentDate = days_date;
      this.local_date = days_date.toString().slice(0, 10);
      console.log(this.local_date);
      this.getWebData().then((response) => {
        console.log(response);
      });
      this.refresher++;
    },
    resetZoomChart() {
      this.fuelChart.resetZoom();
    },
    inPercent() {
      // this.fuelChartData.data.datasets[0].data = this.fuelChartData.data.levels;
      // this.fuelChartData.options.scales.y.max = 110;
      this.renderChart(0);
      // this.chart.resetZoom();
    },
    inLitres() {
      // console.log(this.fuelChartData.options.scales.y.max);
      // this.fuelChartData.data.datasets[0].data = this.fuelChartData.data.litres;
      // this.fuelChartData.options.scales.y.max = 90;
      // this.chart.destroy();
      // console.log(this.fuelChart);
      this.renderChart(1);
      // await new Promise(r => setTimeout(r, 2000));
    },
    renderChart(type) {
      // this.fuelChart.destroy();

      if (this.fuelChart !== null) {
        console.log("Not Nullccccccccccccccccccccccccc");
        this.fuelChart.destroy();
      }
      const ctx = document.getElementById("fuel-chart");
      // if (Object.keys(this.sourceData).length === 0) return;
      if (this.sourceData === null) return;
      let parsed = JSON.parse(this.sourceData.fuel_report);
      let fuel = parsed.fuel;
      // console.table(fuel)
      let timestamp = [];
      let levels = [];
      let litres = [];
      let ignitions = [];
      fuel.forEach((element) => {
        timestamp.push(element["timestamp"]);
        levels.push(element["level"]);
        litres.push(element["litre"]);
        ignitions.push(element["ignition"]);
      });
      // console.log(this.fuelChartData)
      // console.table(litres);
      this.fuelChartData.data.labels = timestamp;
      this.fuelChartData.data.levels = levels;
      this.fuelChartData.data.litres = litres;
      this.fuelChartData.data.ignitions = ignitions;
      if (type === 0) {
        this.fuelChartData.data.datasets[0].label = "Fuel/Time by Percent";
        this.fuelChartData.data.datasets[0].data = levels;
        this.fuelChartData.options.scales.y.max = 110;
      } else {
        this.fuelChartData.data.datasets[0].label = "Fuel/Time by Litres";
        this.fuelChartData.data.datasets[0].data = litres;
        this.fuelChartData.options.scales.y.max = 90;
      }
      // this.fuelChartData.data.datasets[0].ignitions = ignitions;
      // this.fuelChartData.data.datasets[1].label = "Fuel/Time by Litre";
      // this.fuelChartData.data.datasets[1].data = litres;
      this.fuelChart = new Chart(ctx, this.fuelChartData);
      // console.log("litres", this.fuelChartData.data.litres);
      // this.fuelChart.update();
    },
    getWebData() {
      // const url = "https://gyc5tlj7k26jrtgfpy4tzte2240hatpe.lambda-url.us-east-1.on.aws/date=2022-06-10";
      const dt = String(this.currentDate.toString().slice(0, 10));
      const url =
        "https://5uljd3w6h1.execute-api.us-east-1.amazonaws.com/reports" + "?date=" + dt;
      //   const url = "https://5uljd3w6h1.execute-api.us-east-1.amazonaws.com/reports?date=2022-06-10";
      return axios
        .get(url)
        .then((response) => {
          // let result = JSON.parse(response.data)
          let result = response.data;
          // console.log(response.data.distance_km);
          this.sourceData = result;
          console.log(this.sourceData);
          console.log("kms", this.sourceData.distance_km);
          this.renderChart(0);
          return "Success";
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    this.getWebData().then((response) => {
      console.log(response);
      this.refresher++;
    });
  },

  computed: {
    volume() {
      if (this.sourceData === null) return null;
      // if (Object.keys(this.sourceData).length === 0) return null;
      let parsed = JSON.parse(this.sourceData.fuel_report);
      return parsed.volume;
    },
  },
};
</script>
